@each $prop, $abbrev in (margin: m, padding: p) {
	@each $size, $lengths in $spacers {
		$x: map-get($lengths, x);
		$y: map-get($lengths, y);

		.#{$abbrev}-#{$size} {
			#{$prop}: $y $x;
		}

		.#{$abbrev}t-#{$size}, .my-#{$size} { #{$prop}-top: $y !important; }
		.#{$abbrev}r-#{$size}, .mx-#{$size} { #{$prop}-right: $y !important; }
		.#{$abbrev}b-#{$size}, .my-#{$size} { #{$prop}-bottom: $y !important; }
		.#{$abbrev}l-#{$size}, .mx-#{$size} { #{$prop}-left: $y !important; }
	}
}

.ml-auto, .mx-auto {
	margin-left: auto !important;
}
.mr-auto, .mx-auto {
	margin-right: auto !important;
}
