@import './_variables.scss';

.zfss-upload {
	outline: 0;
	font-size: 14px;
	box-sizing: border-box;
	margin: 0;
	padding: 0;

	&.zfss-upload-drag {
		position: relative;
		width: 100%;
		height: 100%;
		text-align: center;
		background: #fafafa;
		border: 1px dashed #d9d9d9;
		border-radius: 2px;
		cursor: pointer;
		transition: border-color .3s;

		.zfss-upload-btn {
			display: table;
			height: 100%;
			width: 100%;
			outline: none;
		}

		.zfss-upload {
			padding: 16px 0;
		}

		.zfss-upload-drag-container {
			display: table-cell;
			vertical-align: middle;
		}

		p {
			margin: 0;

			&.upload-drag-icon {
				margin-bottom: 20px;

				.anticon {
					font-size: 48px;
					color: $primary-light;
				}
			}

			&.upload-text {
				margin: 0 0 4px;
				color: rgba(black, .85);
				font-size: 16px;
			}

			&.upload-hint {
				color: rgba(black, .45);
				font-size: 14px;
			}
		}

		&:not(.zfss-upload-disabled):hover {
			border-color: $primary-light;
		}
	}
}

.zfss-upload-list {
	font-size: 14px;
	margin: 0;
	padding: 0;

	.zfss-upload-list-text-container {
		transition: opacity .3s, height .3s;

		.zfss-upload-span {
			display: block;
			flex: auto;
		}

		&:before {
			display: table;
			width: 0;
			height: 0;
			content: '';
		}
	}

	.zfss-upload-list-item {
		position: relative;
		height: 22px;
		margin-top: 8px;
		font-size: 14px;

		.zfss-upload-list-item-info {
			height: 100%;
			padding: 0 4px;
			transition: background-color .3s;

			& > span {
				height: 100%;
				width: 100%;
			}
		}

		&:hover {
			.zfss-upload-list-item-info {
				background: #f5f5f5;
			}
		}
	}

	&.zfss-upload-list-text {
		.zfss-upload-span {
			display: flex;
			align-items: center;

			* {
				flex: none;
			}
		}

		.zfss-upload-text-icon {
			.anticon {
				position: static;
				top: 5px;
				color: rgba(black, .45);
				font-size: 14px;
			}
		}

		.zfss-upload-list-item-name {
			flex: auto;
			padding: 0 8px;
		}

		.zfss-upload-list-item-card-actions {
			position: static;
			right: 0;

			.zfss-upload-list-item-card-actions-btn {
				height: 20px;
				line-height: 1;
			}
		}
	}

	&:before, &:after {
		display: table;
		content: '';
	}

	&:after {
		clear: both;
	}
}
