$primary: #457b9d;
$primary-light: #0097d6;
$success: #007f5e;
$warning: #fb8501;
$danger: #e73845;
$gray: #B5B5C3;
$darkgray: #575757;

$breakpoints: ('xs': 480px, 'sm': 576px, 'md': 768px, 'lg': 992px, 'xl': 1200px, 'xxl': 1600px);

$spacer: 1rem;
$spacers: (
	0: (
		x: 0,
		y: 0
	),
	h: (
		x: ($spacer * 0.5),
		y: ($spacer * 0.5)
	),
	1: (
		x: ($spacer),
		y: ($spacer)
	),
	2: (
		x: ($spacer * 1.5),
		y: ($spacer * 1.5)
	),
	3: (
		x: ($spacer * 3),
		y: ($spacer * 3)
	),
	4: (
		x: ($spacer * 4),
		y: ($spacer * 4)
	)
);
