@import './_variables.scss';

.dataTables_wrapper {
	position: relative;
	overflow-x: auto;

	.btn {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: 0 10px;
		color: #B5B5C3;
		background-color: transparent;
		outline: none;
		vertical-align: middle;
		cursor: pointer;
		font-family: 'Poppins', 'Open Sans', sans-serif;
		font-size: .925rem;
		line-height: 1.35;
		border-radius: 4px;
		transition: background-color .15s ease, color .15s ease;

		&.btn-table {
			height: calc(1.35em + 1rem + 2px);
		}

		&:hover {
			color: $primary;
			background: #F3F6F9;
		}
	}

	& > .dataTables_head,
	& > .dataTables_foot {
		padding: 15px 20px;
	}

	textarea {
		max-width: 100%;
		height: auto;
		min-height: 32px;
		line-height: 1.5715;
		vertical-align: bottom;
		-webkit-transition: all 0.3s, height 0s;
		transition: all 0.3s, height 0s;

		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		font-variant: tabular-nums;
		list-style: none;
		-webkit-font-feature-settings: 'tnum';
		font-feature-settings: 'tnum';
		position: relative;
		display: inline-block;
		min-width: 0;
		padding: 4px 11px;
		color: rgba(0, 0, 0, 0.85);
		font-size: 14px;
		line-height: 1.5715;
		background-color: #fff;
		background-image: none;
		border: 1px solid #d9d9d9;
		border-radius: 2px;
		-webkit-transition: all 0.3s;
		transition: all 0.3s;

		&::-moz-placeholder {
			opacity: 1;
		}

		&::-webkit-placeholder, &::-ms-input-placeholder, &:-ms-input-placeholder, &::placeholder {
			color: #bfbfbf;
		}

		&:-moz-placeholder-shown, &:-ms-input-placeholder, &:placeholder-shown {
			text-overflow: ellipsis;
		}

		&:hover {
			border-color: #40a9ff;
			border-right-width: 1px !important;
		}

		&:focus {
			border-color: #40a9ff;
			border-right-width: 1px !important;
			outline: 0;
			-webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
			box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
		}

		&[disabled] {
			color: rgba(0, 0, 0, 0.25);
			background-color: #f5f5f5;
			cursor: not-allowed;
			opacity: 1;

			&:hover {
				border-color: #d9d9d9;
				border-right-width: 1px !important;
			}
		}
	}

	select, input {
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		font-variant: tabular-nums;
		list-style: none;
		-webkit-font-feature-settings: 'tnum';
		font-feature-settings: 'tnum';
		position: relative;
		display: inline-block;
		min-width: 0;
		padding: 4px 11px;
		color: rgba(0, 0, 0, 0.85);
		font-size: 14px;
		line-height: 1.5715;
		background-color: #fff;
		background-image: none;
		border: 1px solid #d9d9d9;
		border-radius: 2px;
		-webkit-transition: all 0.3s;
		transition: all 0.3s;
		max-width: 150px;

		&::-moz-placeholder {
			opacity: 1;
		}

		&::-webkit-placeholder, &::-ms-input-placeholder, &:-ms-input-placeholder, &::placeholder {
			color: #bfbfbf;
		}

		&:-moz-placeholder-shown, &:-ms-input-placeholder, &:placeholder-shown {
			text-overflow: ellipsis;
		}

		&:hover {
			border-color: #40a9ff;
			border-right-width: 1px !important;
		}

		&:focus {
			border-color: #40a9ff;
			border-right-width: 1px !important;
			outline: 0;
			-webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
			box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
		}

		&[disabled] {
			color: rgba(0, 0, 0, 0.25);
			background-color: #f5f5f5;
			cursor: not-allowed;
			opacity: 1;

			&:hover {
				border-color: #d9d9d9;
				border-right-width: 1px !important;
			}
		}
	}

	.dataTables_filter, .dataTables_length {
		select {
			margin-left: 5px;
		}

		input {
			margin-left: 10px;
		}
	}

	.dataTables_paginate {
		.paginate_button {
			font-family: 'Poppins', 'Jost', 'Open Sans', sans-serif;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			height: 2rem;
			width: 2rem;
			padding: .5rem;
			position: relative;
			font-size: .75rem;
			font-weight: 500;
			border-radius: 5px;
			margin: 0 3px;
			color: #7E8299;

			-webkit-transition: color .15s ease, background .15s ease;
			transition: color .15s ease, background .15s ease;

			&.disabled {
				opacity: .6;
				cursor: default;
			}

			&.first, &.previous, &.next, &.last {
				background: #ecf1f6;
			}

			&:not(.disabled):hover, &.current {
				background: $primary;
				color: white;
			}
		}
	}

	.dataTables_processing {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: #ffffff;
		padding: 15px 20px;
		color: #333;
		font: 500 14px 'Poppins', 'Jost', 'Open Sans', sans-serif;
		box-shadow: 0px 0px 20px 0px rgba(black, .1);
		border-radius: 4px;
	}
}
table.dataTable {
    width: 100% !important;
    margin: 0;
    clear: both;
    border-collapse: separate;
    border-spacing: 0;
	border-top: 1px solid #EAEAEA;

    thead,
    tfoot {
        th {
			font-family: 'Poppins', 'Jost', 'Open Sans', sans-serif;
            font-weight: 500;
			color: #454554;
			text-transform: uppercase;
			text-align: left;
        }


    }

	thead {
		tr:last-child {
			td, th {
				border-bottom: 2px solid #EAEAEA;
			}
		}

		.sorting {
			cursor: pointer;
        }

        .sorting_asc {

        }

        .sorting_desc {

        }

		.sorting_desc, .sorting_asc {
			cursor: pointer;
            color: $primary;
        }


        .sorting_asc_disabled {

        }

        .sorting_desc_disabled {

        }
	}

	tbody {
		td, th {
			border-bottom: 1px solid #EAEAEA;
		}
	}

	td, th {
		outline: none;
		padding: 6px 15px;
		// white-space: nowrap;
	}
}

table.dataTable thead>tr>th.sorting,
table.dataTable thead>tr>th.sorting_asc,
table.dataTable thead>tr>th.sorting_desc,
table.dataTable thead>tr>th.sorting_asc_disabled,
table.dataTable thead>tr>th.sorting_desc_disabled,
table.dataTable thead>tr>td.sorting,
table.dataTable thead>tr>td.sorting_asc,
table.dataTable thead>tr>td.sorting_desc,
table.dataTable thead>tr>td.sorting_asc_disabled,
table.dataTable thead>tr>td.sorting_desc_disabled {
    cursor: pointer;
    position: relative;
    padding-right: 26px
}

table.dataTable thead>tr>th.sorting:before,
table.dataTable thead>tr>th.sorting:after,
table.dataTable thead>tr>th.sorting_asc:before,
table.dataTable thead>tr>th.sorting_asc:after,
table.dataTable thead>tr>th.sorting_desc:before,
table.dataTable thead>tr>th.sorting_desc:after,
table.dataTable thead>tr>th.sorting_asc_disabled:before,
table.dataTable thead>tr>th.sorting_asc_disabled:after,
table.dataTable thead>tr>th.sorting_desc_disabled:before,
table.dataTable thead>tr>th.sorting_desc_disabled:after,
table.dataTable thead>tr>td.sorting:before,
table.dataTable thead>tr>td.sorting:after,
table.dataTable thead>tr>td.sorting_asc:before,
table.dataTable thead>tr>td.sorting_asc:after,
table.dataTable thead>tr>td.sorting_desc:before,
table.dataTable thead>tr>td.sorting_desc:after,
table.dataTable thead>tr>td.sorting_asc_disabled:before,
table.dataTable thead>tr>td.sorting_asc_disabled:after,
table.dataTable thead>tr>td.sorting_desc_disabled:before,
table.dataTable thead>tr>td.sorting_desc_disabled:after {
    position: absolute;
    display: block;
    opacity: .125;
    right: 10px;
    line-height: 9px;
    font-size: .8em
}

table.dataTable thead>tr>th.sorting:before,
table.dataTable thead>tr>th.sorting_asc:before,
table.dataTable thead>tr>th.sorting_desc:before,
table.dataTable thead>tr>th.sorting_asc_disabled:before,
table.dataTable thead>tr>th.sorting_desc_disabled:before,
table.dataTable thead>tr>td.sorting:before,
table.dataTable thead>tr>td.sorting_asc:before,
table.dataTable thead>tr>td.sorting_desc:before,
table.dataTable thead>tr>td.sorting_asc_disabled:before,
table.dataTable thead>tr>td.sorting_desc_disabled:before {
    bottom: 50%;
    content: "▲"
}

table.dataTable thead>tr>th.sorting:after,
table.dataTable thead>tr>th.sorting_asc:after,
table.dataTable thead>tr>th.sorting_desc:after,
table.dataTable thead>tr>th.sorting_asc_disabled:after,
table.dataTable thead>tr>th.sorting_desc_disabled:after,
table.dataTable thead>tr>td.sorting:after,
table.dataTable thead>tr>td.sorting_asc:after,
table.dataTable thead>tr>td.sorting_desc:after,
table.dataTable thead>tr>td.sorting_asc_disabled:after,
table.dataTable thead>tr>td.sorting_desc_disabled:after {
    top: 50%;
    content: "▼"
}

table.dataTable thead>tr>th.sorting_asc:before,
table.dataTable thead>tr>th.sorting_desc:after,
table.dataTable thead>tr>td.sorting_asc:before,
table.dataTable thead>tr>td.sorting_desc:after {
    opacity: .6
}

table.dataTable thead>tr>th.sorting_desc_disabled:after,
table.dataTable thead>tr>th.sorting_asc_disabled:before,
table.dataTable thead>tr>td.sorting_desc_disabled:after,
table.dataTable thead>tr>td.sorting_asc_disabled:before {
    display: none
}


table.dataTable td.sorting_1 { background-color: lighten($primary, 50%);}

table.dataTable .last-col-sticky {
	position: sticky;
	right: 0px;
	width: 1%;
	background-color: #fff;
	border-left: 1px solid #EAEAEA;
	box-shadow: -5px 0px 5px -3px rgba(0, 0, 0, 0.05);
}