@import '~antd/dist/antd.css';

@import './_variables.scss';

@import './mixins.scss';
@import './spacing.scss';

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

body {
	font-family: 'Open Sans', sans-serif;
}

#root, .app, body {
	height: 100%;
}

a {
	color: $primary-light;

	&:hover {
		color: darken($primary-light, 10%);
	}
}

.ant-upload.ant-upload-drag {
	p.ant-upload-drag-icon {
		.anticon {
			color: $primary-light;
		}
	}
}

.ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
	border-color: $primary;
}

.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
	border-color: $primary;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
	.ant-select-selector {
		border-color: $primary;
	}
}

.ant-select:not(.ant-select-disabled):hover {
	.ant-select-selector {
		border-color: $primary;
	}
}

.ant-switch-checked {
	background-color: $primary;
}

.ant-input:focus, .ant-input-focused {
	border-color: $primary;
}

.ant-input:hover {
	border-color: $primary;
}

.ant-picker-focused {
	border-color: $primary;
}

.ant-picker:hover, .ant-picker-focused {
	border-color: $primary;
}

.ant-tabs-tab {
	&.ant-tabs-tab-active {
		.ant-tabs-tab-btn {
			color: $primary;
		}
	}

	&:hover {
		.ant-tabs-tab-btn {
			color: $primary;
		}
	}
}

.ant-tabs-ink-bar {
	background: $primary;
}

.ant-card {
	box-shadow: 0px 0px 8px 0px rgba(black, .05);
	border-radius: 4px;
}

.ant-btn {
	border-radius: 3px;
}

.ant-btn-primary {
	background: $primary;
	border-color: $primary;

	&:hover, &:focus {
		background: #5c94b7;
		border-color: #5c94b7;
	}
}

.container {
	width: 100%;
	padding-left: .75rem;
	padding-right: .75rem;
	margin-left: auto;
	margin-right: auto;

	@each $modifier, $breakpoint in $breakpoints {
		@media (min-width: $breakpoint) {
			& {
				max-width: $breakpoint;
			}
		}
	}
}

.main {
	display: block;
	width: 100%;
	min-width: 0;
	position: relative;

	&::before {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background: url('../images/background.jpg') no-repeat center center;
		background-size: cover;
		opacity: .15;
		content: '';
		z-index: -1;
	}
}

.userPhoto {
	vertical-align: middle;
	display: inline-block;
	line-height: 0;
	position: relative;
	margin: 2px;
	border-radius: 100%;

	img {
		border-radius: inherit;
		width: 100%;
		height: 100%;
		vertical-align: top;
		object-fit: cover;
	}
}

.btn-link {
	display: flex;

	justify-content: center;
	align-items: center;
}

.text-muted {
	color: #B5B5C3;
}

.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.d-none {
	display: none;
}

.d-block {
	display: block;
}

.d-inline-block {
	display: inline-block;
}

.d-flex {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.d-table {
	display: table;
}

.d-table-cell {
	display: table-cell;
}

.align-middle {
	vertical-align: middle;
}

.h-100 {
	height: 100% !important;
}

.w-100 {
	width: 100% !important;
}

.ant-tooltip {
	font-size: 12px;
}

.zfss-markdown {
	code {
		padding: .2em .4em;
		margin: 0;
		font-size: 85%;
		background-color: #f3f4f4;
		border-radius: 6px;
	}

	pre {
		padding: .4em .6em;
		margin: 0;
		font-size: 85%;
		background-color: #f3f4f4;
		border-radius: 6px;

		code {
			background-color: initial;
			border-radius: initial;
			font-size: inherit;
			padding: initial;
		}
	}

	h1, h2 {
		padding-bottom: .3em;
		border-bottom: 1px solid #eaecef;
	}

	h1, h2, h3, h4, h5, h6 {
		font-weight: 600;
	}
}

// adds scroll on lower res for antd table inside tabs
.antd-table-responsive {
	display: block;
	width: 100%;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar;
}