@import 'global.scss';

.menu-nav {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;

	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;

	list-style: none;
	margin: 0;
	padding: 0;

	font-family: 'Poppins', 'Open Sans', sans-serif;

	.menu-item {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;

		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;

		padding: 0 .25rem;
		margin: 0 1.6rem;

		.menu-text {
			display: flex;

			align-items: center;
			flex-grow: 1;

			font-size: .9rem;
			font-weight: 400;
		}

		&:first-child {
			margin-left: 0;
		}

		&.menu-item-submenu {
			position: relative;

			.menu-submenu {
				@media (min-width: 992px) {
					max-height: 80vh;
					overflow-y: auto;
					overflow-x: hidden;
				}
				display: none;
				margin: 0;
				min-width: 100%;
				min-width: 275px;
				padding: 15px 0;

				z-index: 2;

				position: absolute;
				left: 0;
				right: auto;
				top: 100%;

				text-align: left;

				background: #fff;
				border-radius: 4px;

				box-shadow: 0px 0px 10px 0px rgba(black, .15);

				&.menu-submenu-right {
					left: auto;
					right: 0;
				}

				&.menu-submenu-left {
					left: 0;
					right: auto;
				}

				.menu-subnav {
					margin: 0;
					padding: 0;

					.menu-item {
						padding: 0;
						margin: 0;

						.menu-submenu {
							top: 0;
							left: 100%;
							right: auto;
						}

						.menu-link {
							white-space: nowrap;
							text-transform: none;
							font-weight: 400;
							color: $darkgray;

							padding: 11px 30px;

							@include transition(color, .1s, ease-in-out);
							@include transition(background-color, .1s, ease-in-out);
						}

						.menu-arrow {
							display: flex;
							justify-content: flex-end;
							align-items: center;

							width: 20px;
							padding: 0 0 0 10px;

							font-size: .6rem;

							color: $darkgray;
						}

						&:hover {
							& > .menu-link {
								background-color: rgba($primary, .15);
								color: $primary-light;

								& > .menu-icon {
									color: $primary-light;
								}

								& > .menu-arrow {
									color: $primary-light;
								}
							}
						}
					}
				}
			}

			&.menu-item-open {
				& > .menu-submenu {
					display: -webkit-box;
				    display: -ms-flexbox;
				    display: flex;

				    -webkit-box-flex: 1;
				    -ms-flex-positive: 1;
				    flex-grow: 1;

				    -webkit-box-orient: vertical;
				    -webkit-box-direction: normal;
				    -ms-flex-direction: column;
				    flex-direction: column;

					animation: header-menu-submenu-fade-in .3s ease-out 1, header-menu-submenu-slide-up .3s ease-out 1;
				}

				& > .menu-link {
					color: $primary-light;

					.menu-icon {
						color: $primary-light;
					}

					.menu-arrow {
						color: $primary-light;
					}
				}
			}

			.menu-link {

				&:hover, &:focus {
					.menu-icon {
						color: $primary-light;
					}

					.menu-arrow {
						color: $primary-light;
					}
				}

				.menu-arrow {
					display: -webkit-box;
				    display: -ms-flexbox;
				    display: flex;

				    -webkit-box-align: center;
				    -ms-flex-align: center;
				    align-items: center;

				    -webkit-box-pack: flex-end;
				    -ms-flex-pack: flex-end;
				    justify-content: flex-end;

					-webkit-box-flex: 0;
					-ms-flex: 0 0 15px;
					flex: 0 0 15px;

					font-size: .55rem;
					color: white;
				}
			}
		}

		.menu-link {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;

			flex-grow: 1;

			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;

			color: white;

			@include transition(color, .1s, ease-in-out);

			&:hover, &:focus, &.menu-item-active {
				color: $primary-light;

				.menu-icon {
					color: $primary-light;
				}
			}

			.menu-icon {
				display: -webkit-box;
			    display: -ms-flexbox;
			    display: flex;

			    -webkit-box-align: center;
			    -ms-flex-align: center;
			    align-items: center;

				-webkit-box-flex: 0;
				-ms-flex: 0 0 28px;
				flex: 0 0 28px;

				font-size: .90rem;

				color: white;
			}

		}

		.menu-badge {
			padding: 0px 7px;
			background: #457B9D;
			color: white;
			border-radius: 3px;
			margin-left: 15px;
		}
	}
}

.ant-drawer.drawer-menu {
	.ant-drawer-body {
		padding-left: 0;
		padding-right: 0;
	}

	.menu-nav {
		flex-direction: column;

		.menu-item {
			align-items: initial;
			margin: 0;
			padding: 0;

			&.menu-item-submenu {
				flex-direction: column;

				.menu-submenu {
					box-shadow: none;
					display: flex;
					position: initial;
					padding: 0;

					animation: none;

					.menu-subnav {
						.menu-item {
							.menu-link {
								padding-left: 50px;
							}
						}
					}
				}

				.menu-link {
					.menu-arrow {
						color: #333;
					}
				}
			}

			.menu-link {
				padding: 11px 30px;
				color: #333;

				.menu-icon {
					color: #333;
				}

				&:hover {
					background-color: rgba($primary, .15);
					color: $primary-light;

					.menu-icon {
						color: $primary-light;
					}
				}
			}
		}
	}
}

@keyframes header-menu-submenu-slide-down {
	0% {
		transform: translateY(-10%);
	}

	100% {
		transform: translateY(0);
	}
}

@keyframes header-menu-submenu-slide-up {
	0% {
		transform: translateY(10%);
	}

	100% {
		transform: translateY(0);
	}
}

@keyframes header-menu-submenu-fade-in {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
