@import 'global.scss';

.login-pane {
	.login-card {
		box-shadow: 0px 0px 6px 0px rgba(black, .15);

		.ant-card-head {
			position: relative;
			background: $primary;
			color: white;
			text-align: center;
			font-family: 'Poppins', sans-serif;

			padding-bottom: 35px;

			.login-card-title {
				font-size: 24px;
			}

			.login-card-subtitle {
				margin: 0;
				font-size: 13px;
				font-weight: 400;
				margin-top: 10px;
			}

			&::after {
				display: block;
				content: '';
				clear: both;

				position: absolute;
				bottom: -73px;
				left: 50%;

				background: url('../images/logos/impel_sygnet_color.png') no-repeat center center #FFF;
				background-size: 100% 100%;

				width: 73px;
				height: 73px;
				border-radius: 100%;
				border: 6px solid #FFF;
				box-shadow: 0px 2px 0px -1px rgba(#0072a3, 1);

				transform: translate(-50%, -50%);
			}
		}

		.ant-card-body {
			padding-top: calc(24px + 20px);
		}
	}
}
