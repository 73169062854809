@import 'global.scss';

html, body {
	height: 100%;
}

body {
	background-color: #f3f7f9;
}

.main {
	background: #f2f6ff;
	box-shadow: inset 0px 17px 14px -11px rgba(0, 0, 0, 0.25);
	height: 100%;

	&::before {
		display: none;
	}
}

.menu-btn {

}

@media (min-width: 992px) {
	.menu-btn {
		display: none;
	}
}

@media (max-width: 992px) {
	.menu-nav:not(.mobile) {
		display: none !important;
	}
}

#tableFilters {
	padding: 1rem 2rem;
	opacity: 0;
    transform: scaleY(0);
    display: none;
	border-bottom: 1px solid #eaeaea;

	transition: opacity .3s ease,
				transform .3s ease;

	&.shown {
		opacity: 1;
	    transform: scaleY(1);
	    display: inherit;

		animation: filters-fade-down .3s ease-out 1;
	}
}

.ant-layout {
	background: transparent;
	height: 100%;

	.ant-layout-header {
		background: white;
		box-shadow: 0px 5px 5px -5px rgba(black, .3);
		border-top: 2px solid $primary-light;

		.logo {
			img {
				width: 100px;
			}
		}

		.header__notification_wrapper {
			position: relative;
			color: $gray;

			.indicator {
				display: block;
				width: 10px;
				height: 10px;
				border: 2px solid white;
				border-radius: 100%;
				background: red;
				position: absolute;
				top: -1px;
				right: -4px;

				@include transition(top, .1s, ease-in);
			}

			&:hover .indicator {
				top: -6px;
			}
		}

		.header__userPane {
			margin-left: 25px;

			a {
				align-items: center;

				.userPhoto {
					margin-right: 10px;
				}

				& > span {
					margin-right: 13px;

					& > span {
						font-size: 12px;
					}
				}

				.username {
					line-height: 1;
					color: $darkgray;
				}

				.anticon, [data-icon] {
					color: $primary;
				}

				line-height: initial;
			}
		}
	}

	.navbar {
		background: #455260;
		height: 54px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;

		align-items: center;

		.menu-nav {
			height: 100%;

			.menu-item {
				&.menu-item-submenu {
					.menu-link {

						&:hover, &:focus {
							.menu-arrow {

							}
						}
					}

					.menu-submenu {
						.menu-icon {
							color: $darkgray;
						}
					}
				}

				.menu-link {
					font-family: 'Poppins', 'Open Sans', sans-serif;
					text-transform: uppercase;
					font-weight: 500;


					&:hover, &:focus {

					}
				}
			}
		}
	}

	.ant-layout-content {
		flex: 1 0 auto;
	}

	.content {
		padding: 3rem 0;

		.subheader {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 3rem;

			& > div {
				flex-direction: column;

				.title {
					font-size: 16px;
					font-weight: 700;

					margin: 0;
					padding: 0;
				}

				.subtitle {
					font-size: 13px;
				}
			}

			.subheader_actions {
				display: flex;
				flex-direction: row;

				a, button {
					margin-left: .5rem;

					&:first-child {
						margin-left: 0;
					}
				}
			}

			.ant-affix {
				position: absolute;
				left: 0 !important;
				width: 100% !important;
				z-index: 1;

				.subheader_actions {
					padding: 10px 0;
					width: 100%;
					background: #fff;
					box-shadow: 0 2px 6px 0 rgba(black, .2);

					.container {
						display: flex;
						justify-content: space-between;
						align-items: center;
					}
				}
			}
		}

		.toolbar {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.toolbar_actions {
				display: flex;
				margin-left: auto;
				flex-direction: row;

				a, button {
					margin-left: .5rem;
				}

				a:first-child, button:first-child {
					margin-left: 0;
				}
			}
		}

		.record {
			.ant-form-item {
				margin-bottom: 0;
			}
		}
	}

	.ant-layout-footer {
		background: white;
		box-shadow: 0px -3px 5px -5px rgba(black, .3);
		border-top: 1px solid #d5d5dd;
		flex-shrink: 0;

		.footer {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;

			justify-content: space-between;
			align-items: center;

			.footer__meta {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;

				align-items: center;

				.footer__title {
					font-size: 14px;
					margin: 0;
					padding: 0;
				}

				.footer__build {
					font-size: 12px;
				}

				.footer__logo {
					width: 24px;
					height: 24px;

					margin-right: 15px;
				}
			}

			.menu-item {
				&.menu-item-submenu {
					.menu-link {

						&:hover, &:focus {
							.menu-arrow {

							}
						}
					}
				}

				.menu-link {
					color: $gray;

					&:hover, &:focus {
						color: $primary-light;
					}
				}
			}
		}
	}
}

.upload-file-list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.ant-table-thead {
	tr {
		th {
			font-family: 'Poppins', 'Open Sans', sans-serif;
		}
	}
}

.form-group-row {
	margin-bottom: 0 !important;

	.form-group {
		display: flex;
		flex-direction: column;
	}
}

.ant-card.card-collapse {
	&.card-collapsed {
		.ant-card-head {
			.ant-card-head-wrapper {
				.ant-card-extra, .ant-card-head-title {
					padding-bottom: 16px;
				}
			}

			.ant-tabs {
				display: none;
			}
		}

		.ant-card-body {
			height: 0;
		}
	}

	.ant-card-body {
		transition: height .3s ease-in-out;
		height: auto;
		overflow: hidden;
	}

	.ant-card-head-title {
		font-weight: 600;
	}
}

@keyframes filters-fade-down {
	0% {
		display: none;
		opacity: 0;
		transform: scaleY(0);
	}

	100% {
		display: inherit;
		opacity: 1;
		transform: scaleY(1);
	}
}
